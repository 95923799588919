<template>
  <div style="background-color: white; height: 30%">
    <div style="height: 10px"></div>
    <div style="margin: 0 0 0 30px">
      <a style="font-size: xx-large; color: black">基本设置</a>
      <div style="display: flex; align-items: center; margin: 20px 0 0 20px">
        <a style="color: black; font-size: large">用户头像：</a>
        <el-avatar
          style="margin-left: 40px"
          size="large"
          :src="userInfo.avatar"
        ></el-avatar>
      </div>
      <div style="display: flex; align-items: center; margin: 20px 0 0 20px">
        <a style="color: black; font-size: large">账户名称：</a>
        <span style="margin-left: 40px; font-size: large">{{
          userInfo.username
        }}</span>
      </div>
      <div style="margin: 30px">
        <a style="color: #40a0ff" @click="openRepHandle">修改密码</a>
      </div>

      <el-dialog title="用户信息" v-model="repasDialog" width="600px">
        <el-form
          :model="passForm"
          status-icon
          :rules="rules"
          ref="passForm"
          label-width="100px"
        >
          <el-form-item label="旧密码" prop="currentPass">
            <el-input
              type="password"
              v-model="passForm.currentPass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input
              type="password"
              v-model="passForm.password"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="checkPass">
            <el-input
              type="password"
              v-model="passForm.checkPass"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('passForm')"
              >提交</el-button
            >
            <el-button @click="resetForm('passForm')">重置</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from "@/http/HTTP";
import { ElMessage } from "element-plus";
export default {
  name: "Login",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      userInfo: {},
      passForm: {
        password: "",
        checkPass: "",
        currentPass: "",
      },
      repasDialog: false,
      rules: {
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "长度在 6 到 12 个字符",
            trigger: "blur",
          },
        ],
        checkPass: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        currentPass: [
          { required: true, message: "请输入当前密码", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getUserInfo();
  },
  methods: {
    getUserInfo() {
      axios.get("/sys/userInfo").then((res) => {
        this.userInfo = res.data;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this;
          axios.post("/sys/user/updatePass", this.passForm).then((res) => {
            if (res.code === 200) {
              ElMessage({
                message: res.msg,
                type: "success",
              });
              this.$refs[formName].resetFields();
              this.repasDialog = false;
            }else{
                ElMessage.error(res.msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    openRepHandle() {
      this.repasDialog = true;
    },
  },
};
</script>

<style scoped>
.el-form {
  width: 420px;
  margin: 50px auto;
}
</style>
